// Generated by Framer (2372734)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["tt_uG789p"];

const variantClassNames = {tt_uG789p: "framer-v-1uuil6r"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, image, width, ...props}) => { return {...props, Z1D61yGoF: image ?? props.Z1D61yGoF ?? {src: new URL("assets/A3hwJrlRMIBbKXP72pvvxTC0dA.webp", import.meta.url).href}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Z1D61yGoF, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "tt_uG789p", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5Nsgs", classNames)} style={{display: "contents"}}>
<Transition value={transition}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 2483.6363098049965, intrinsicWidth: 3723.636282928721, pixelHeight: 2732, pixelWidth: 4096, sizes: "min(3724px, 100vw)", ...toResponsiveImage(Z1D61yGoF)}} className={cx("framer-1uuil6r", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"tt_uG789p"} ref={ref} style={{...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5Nsgs [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5Nsgs .framer-14t4p40 { display: block; }", ".framer-5Nsgs .framer-1uuil6r { height: 2484px; overflow: visible; position: relative; width: 3724px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2484
 * @framerIntrinsicWidth 3724
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Z1D61yGoF":"image"}
 */
const Framerx5TvuOngM: React.ComponentType<Props> = withCSS(Component, css, "framer-5Nsgs") as typeof Component;
export default Framerx5TvuOngM;

Framerx5TvuOngM.displayName = "EXXwjas6I8Bk80bu5KqW9BYtiI4";

Framerx5TvuOngM.defaultProps = {height: 2484, width: 3724};

addPropertyControls(Framerx5TvuOngM, {Z1D61yGoF: {__defaultAssetReference: "data:framer/asset-reference,A3hwJrlRMIBbKXP72pvvxTC0dA.webp?originalFilename=EXXwjas6I8Bk80bu5KqW9BYtiI4.webp&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(Framerx5TvuOngM, [])